/* Flowchart variables */
/* Sequence Diagram variables */
/* Gantt chart variables */
.mermaid .label {
  color: #333;
}
.node rect,
.node circle,
.node ellipse,
.node polygon {
  fill: #ECECFF;
  stroke: #CCCCFF;
  stroke-width: 1px;
}
.edgePath .path {
  stroke: #333333;
}
.edgeLabel {
  background-color: #e8e8e8;
}
.cluster rect {
  fill: #ffffde !important;
  rx: 4 !important;
  stroke: #aaaa33 !important;
  stroke-width: 1px !important;
}
.cluster text {
  fill: #333;
}
.actor {
  stroke: #CCCCFF;
  fill: #ECECFF;
}
text.actor {
  fill: black;
  stroke: none;
}
.actor-line {
  stroke: grey;
}
.messageLine0 {
  stroke-width: 1.5;
  stroke-dasharray: "2 2";
  marker-end: "url(#arrowhead)";
  stroke: #333;
}
.messageLine1 {
  stroke-width: 1.5;
  stroke-dasharray: "2 2";
  stroke: #333;
}
#arrowhead {
  fill: #333;
}
#crosshead path {
  fill: #333 !important;
  stroke: #333 !important;
}
.messageText {
  fill: #333;
  stroke: none;
}
.labelBox {
  stroke: #CCCCFF;
  fill: #ECECFF;
}
.labelText {
  fill: black;
  stroke: none;
}
.loopText {
  fill: black;
  stroke: none;
}
.loopLine {
  stroke-width: 2;
  stroke-dasharray: "2 2";
  marker-end: "url(#arrowhead)";
  stroke: #CCCCFF;
}
.note {
  stroke: #aaaa33;
  fill: #fff5ad;
}
.noteText {
  fill: black;
  stroke: none;
  font-family: 'trebuchet ms', verdana, arial;
  font-size: 14px;
}
/** Section styling */
.section {
  stroke: none;
  opacity: 0.2;
}
.section0 {
  fill: rgba(102, 102, 255, 0.49);
}
.section2 {
  fill: #fff400;
}
.section1,
.section3 {
  fill: white;
  opacity: 0.2;
}
.sectionTitle0 {
  fill: #333;
}
.sectionTitle1 {
  fill: #333;
}
.sectionTitle2 {
  fill: #333;
}
.sectionTitle3 {
  fill: #333;
}
.sectionTitle {
  text-anchor: start;
  font-size: 11px;
  text-height: 14px;
}
/* Grid and axis */
.grid .tick {
  stroke: lightgrey;
  opacity: 0.3;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
/* Today line */
.today {
  fill: none;
  stroke: red;
  stroke-width: 2px;
}
/* Task styling */
/* Default task */
.task {
  stroke-width: 2;
}
.taskText {
  text-anchor: middle;
  font-size: 11px;
}
.taskTextOutsideRight {
  fill: black;
  text-anchor: start;
  font-size: 11px;
}
.taskTextOutsideLeft {
  fill: black;
  text-anchor: end;
  font-size: 11px;
}
/* Specific task settings for the sections*/
.taskText0,
.taskText1,
.taskText2,
.taskText3 {
  fill: white;
}
.task0,
.task1,
.task2,
.task3 {
  fill: #8a90dd;
  stroke: #534fbc;
}
.taskTextOutside0,
.taskTextOutside2 {
  fill: black;
}
.taskTextOutside1,
.taskTextOutside3 {
  fill: black;
}
/* Active task */
.active0,
.active1,
.active2,
.active3 {
  fill: #bfc7ff;
  stroke: #534fbc;
}
.activeText0,
.activeText1,
.activeText2,
.activeText3 {
  fill: black !important;
}
/* Completed task */
.done0,
.done1,
.done2,
.done3 {
  stroke: grey;
  fill: lightgrey;
  stroke-width: 2;
}
.doneText0,
.doneText1,
.doneText2,
.doneText3 {
  fill: black !important;
}
/* Tasks on the critical line */
.crit0,
.crit1,
.crit2,
.crit3 {
  stroke: #ff8888;
  fill: red;
  stroke-width: 2;
}
.activeCrit0,
.activeCrit1,
.activeCrit2,
.activeCrit3 {
  stroke: #ff8888;
  fill: #bfc7ff;
  stroke-width: 2;
}
.doneCrit0,
.doneCrit1,
.doneCrit2,
.doneCrit3 {
  stroke: #ff8888;
  fill: lightgrey;
  stroke-width: 2;
  cursor: pointer;
  shape-rendering: crispEdges;
}
.doneCritText0,
.doneCritText1,
.doneCritText2,
.doneCritText3 {
  fill: black !important;
}
.activeCritText0,
.activeCritText1,
.activeCritText2,
.activeCritText3 {
  fill: black !important;
}
.titleText {
  text-anchor: middle;
  font-size: 18px;
  fill: black;
}
/*


*/
.node text {
  font-family: 'trebuchet ms', verdana, arial;
  font-size: 14px;
}
div.mermaidTooltip {
  position: absolute;
  text-align: center;
  max-width: 200px;
  padding: 2px;
  font-family: 'trebuchet ms', verdana, arial;
  font-size: 12px;
  background: #ffffde;
  border: 1px solid #aaaa33;
  border-radius: 2px;
  pointer-events: none;
  z-index: 100;
}
